const CityDataJSON = [
	{
		id: 1,
		name: "Wellington",
		location: { lat: -41.297, lng: 174.77 },
		zoomLevel: 12,
		suburbs: [
			{ 
				name: "Aro Valley",
				id: 1,
				coords: [
					{lat: -41.298345320645474, lng: 174.75336728603784},
					{lat: -41.293766856130354, lng: 174.75461183091988},
					{lat: -41.29405705003987, lng: 174.7568005132986},
					{lat: -41.29044565612612, lng: 174.76006207919633},
					{lat: -41.289832989109954, lng: 174.75860295761052},
					{lat: -41.28912357800869, lng: 174.75946126442568},
					{lat: -41.28973625168673, lng: 174.76164994680443},
					{lat: -41.29167097289806, lng: 174.76156411612288},
					{lat: -41.292864022378325, lng: 174.7676580945107},
					{lat: -41.29525005588253, lng: 174.77117715245296},
					{lat: -41.297829453385326, lng: 174.7695463695041},
					{lat: -41.297249097840606, lng: 174.76692853371782},
					{lat: -41.29850652832752, lng: 174.76619897292488},
					{lat: -41.29805514581368, lng: 174.7596329257887},
					{lat: -41.29924807853285, lng: 174.75830255022515},
					{lat: -41.298345320645474, lng: 174.75336728603784}
				]
			},
			{
				name: "Berhampore",
				id: 5,
				coords: [
					{lat: -41.32974552426537, lng: 174.78570370493944},
					{lat: -41.32645838432381, lng: 174.78570370493944},
					{lat: -41.32407349272073, lng: 174.78235630828897},
					{lat: -41.316402573858525, lng: 174.77832226617173},
					{lat: -41.31614471216179, lng: 174.7695675364705},
					{lat: -41.32471806662648, lng: 174.76905255237043},
					{lat: -41.32478252366638, lng: 174.76441769546977},
					{lat: -41.32806974815475, lng: 174.7645035261531},
					{lat: -41.328520922886185, lng: 174.77952389573855},
					{lat: -41.330196687392245, lng: 174.7856178742561},
					{lat: -41.32974552426537, lng: 174.78570370493944}
				]
			},
			{
				name: "Breaker Bay",
				id: 6,
				coords: [
					{ lat: -41.341393608074725, lng: 174.8180461360353 },
					{ lat: -41.34333520318529, lng: 174.8171225224913 },
					{ lat: -41.34458334090171, lng: 174.82063225395854 },
					{ lat: -41.33996048883724, lng: 174.82488087626095 },
					{ lat: -41.33492120629161, lng: 174.82580448980497 },
					{ lat: -41.33288689003526, lng: 174.8282058850194 },
					{ lat: -41.331314874869385, lng: 174.83066885447008 },
					{ lat: -41.32974282176918, lng: 174.83516377371757 },
					{ lat: -41.33149981979899, lng: 174.83836563400345 },
					{ lat: -41.32576628283837, lng: 174.838488782476 },
					{ lat: -41.32937292140917, lng: 174.83719572351438 },
					{ lat: -41.32877182884489, lng: 174.83307024968448 },
					{ lat: -41.330575089900535, lng: 174.82894477585455 },
					{ lat: -41.341393608074725, lng: 174.8180461360353 },
				]
			},
			{
				name: "Brooklyn",
				id: 7,
				coords: [
					{ lat: -41.35777911064898, lng:  174.71361589053396 }, 
					{ lat: -41.34602236400539, lng:  174.69329793817627 }, 
					{ lat: -41.32631710998814, lng:  174.70557336772575 }, 
					{ lat: -41.31423674976129, lng:  174.70430349570336 }, 
					{ lat: -41.30596994996442, lng:  174.72927764547637 }, 
					{ lat: -41.298815142545536, lng:  174.73054751749874 }, 
					{ lat: -41.30040516758788, lng:  174.7377434589588 }, 
					{ lat: -41.30517501012725, lng:  174.73308726154346 }, 
					{ lat: -41.31519054382276, lng:  174.73308726154346 }, 
					{ lat: -41.30819572995039, lng:  174.7464209177782 }, 
					{ lat: -41.29817912167463, lng:  174.75255863255293 }, 
					{ lat: -41.29754309460148, lng:  174.7688553235065 }, 
					{ lat: -41.310103480892344, lng:  174.76970190485474 }, 
					{ lat: -41.32361511856992, lng:  174.7595429286759 }, 
					{ lat: -41.32297933960047, lng:  174.75255863255293 }, 
					{ lat: -41.31169323070127, lng:  174.74726749912645 }, 
					{ lat: -41.31948244439141, lng:  174.73351055221758 },
					{ lat: -41.32615817241798, lng:  174.73351055221758 }, 
					{ lat: -41.34125551051058, lng:  174.7110761464893 }, 
					{ lat: -41.35777911064898, lng:  174.71361589053396 },
				]
			},
			{ 
				name: 'Churton Park',
				id: 8,
				coords: [
					{ lat: -41.18529521676551, lng:  174.80202143038954 },
					{ lat: -41.197631660507106, lng:  174.80030481668638 },
					{ lat: -41.202733489053934, lng:  174.7960132824285 },
					{ lat: -41.20460621239937, lng:  174.80021898600123 },
					{ lat: -41.215002081112885, lng:  174.7972149120207 },
					{ lat: -41.217520086488335, lng:  174.81111948301623 },
					{ lat: -41.210094920352084, lng:  174.8155826786444 },
					{ lat: -41.20118360851514, lng:  174.81189195918262 },
					{ lat: -41.1933044815892, lng:  174.81858675262492 },
					{ lat: -41.189299971648104, lng:  174.80863039314667 },
					{ lat: -41.18600575566645, lng:  174.81146280575686 },
					{ lat: -41.182323788734976, lng:  174.80442468957398 },
					{ lat: -41.18529521676551, lng:  174.80202143038954 }
				]
			},
			{ 
				name: 'Crofton Downs',
				id: 9,
				coords: [
					{ lat: -41.25958850810941, lng: 174.76908257933243 },
					{ lat: -41.25962715079097, lng: 174.76908257933243 },
					{ lat: -41.255724124493256, lng: 174.76671794700835 },
					{ lat: -41.25344403091913, lng: 174.76944241468607 },
					{ lat: -41.25155033338729, lng: 174.76718059246306 },
					{ lat: -41.25340538457944, lng: 174.76250273286536 },
					{ lat: -41.248149269390616, lng: 174.75839032882342 },
					{ lat: -41.25039089964262, lng: 174.75268436821526 },
					{ lat: -41.25398507727421, lng: 174.755306025792 },
					{ lat: -41.25703804057558, lng: 174.7511936217501 },
					{ lat: -41.26306625787936, lng: 174.7597782651876 }
				]
			},
			{ 
				name: 'Glenside',
				id: 10,
				coords: [
					{ lat: -41.1881553788727, lng: 174.81278069201218 },
					{ lat: -41.19048230069365, lng: 174.8126040005921 },
					{ lat: -41.19340745650774, lng: 174.81869985458394 },
					{ lat: -41.19925737603156, lng: 174.81348745769236 },
					{ lat: -41.19939032267193, lng: 174.81092543210164 },
					{ lat: -41.20823066815333, lng: 174.81233896346203 },
					{ lat: -41.20995871643892, lng: 174.81587279186311 },
					{ lat: -41.196664862568234, lng: 174.8248840542858 },
					{ lat: -41.19393928897721, lng: 174.82974306833722 },
					{ lat: -41.189551541889166, lng: 174.8310682539876 },
					{ lat: -41.1881553788727, lng: 174.81295738343223 }
				]
			},
			{ 
				name: 'Grenada North',
				id: 11,
				coords: [
					{ lat: -41.179645150135286, lng: 174.8368737822981 },
					{ lat: -41.18229371171633, lng: 174.83326889335953 },
					{ lat: -41.193920275671374, lng: 174.82983566579892 },
					{ lat: -41.1955994975982, lng: 174.83052231131103 },
					{ lat: -41.19618075669047, lng: 174.83455635369475 },
					{ lat: -41.19611617260176, lng: 174.83678795160913 },
					{ lat: -41.189915803397916, lng: 174.835929644719 },
					{ lat: -41.190238753790545, lng: 174.84013534848071 },
					{ lat: -41.193920275671374, lng: 174.84271026915113 },
					{ lat: -41.19404944811814, lng: 174.84580017395567 },
					{ lat: -41.19275771218047, lng: 174.85266662907682 },
					{ lat: -41.18184152585811, lng: 174.85498405768024 },
					{ lat: -41.179580549734254, lng: 174.83704544367615 },					 
					{ lat: -41.179645150135286, lng: 174.8368737822981 },
				]
			},
			{ 
				name: 'Grenada Village',
				id: 12,
				coords: [
					{ lat: -41.18977106656658, lng: 174.8361617680363 }, 
					{ lat: -41.19616520198158, lng: 174.8368484134885 }, 
					{ lat: -41.1946151658972, lng: 174.82903782147028 }, 
					{ lat: -41.20359194891244, lng: 174.82251468967488 }, 
					{ lat: -41.210049304682194, lng: 174.81556240447188 }, 
					{ lat: -41.21185725010044, lng: 174.8212272294521 }, 
					{ lat: -41.212438364802885, lng: 174.82697788511382 }, 
					{ lat: -41.20223582323789, lng: 174.83393017031685 }, 
					{ lat: -41.202687868251914, lng: 174.83702007485152 }, 
					{ lat: -41.19855476903406, lng: 174.8432857146024 }, 
					{ lat: -41.190223197669745, lng: 174.8401099793862 }, 
					{ lat: -41.18977106656658, lng: 174.83590427599177 }
				]
			},
			{
				name: "Broadmeadows", 
				id: 2,
				coords: [
				    { lat: -41.2249841590685, lng: 174.78624280722048 },
				    { lat: -41.23222664382046, lng: 174.78640154122328 },
				    { lat: -41.236325057173254, lng: 174.78941748727635 },
				    { lat: -41.24002534557005, lng: 174.79481444337137 },
				    { lat: -41.23823490960111, lng: 174.79756583275315 },
				    { lat: -41.2336591281827, lng: 174.7978303894245 },
				    { lat: -41.2249841590685, lng: 174.78624280722048 }
				]
			},
			{
				name: "Khandallah",
				id: 3,
				coords: [
					{ 	lat: -41.25156450632053,  lng: 174.80860343105206 },
					{	lat: -41.255694256213204, lng: 174.80070700835233 },
					{	lat: -41.256468555252766, lng: 174.78388419477469 },
					{	lat: -41.24898328006045,  lng: 174.78319754932255 },
					{	lat: -41.242400691508834, lng: 174.77478614253374 },
					{	lat: -41.23220282363847,  lng: 174.7866307765833 },
					{	lat: -41.24846702257068,  lng: 174.8098050605933 }
				]
			},
			{
				name: "Johnsonville",
				id: 4,
				coords: [
					{ lat: -41.238415172023544, lng: 174.7978252306068 }, 
					{ lat: -41.23299338823318, lng: 174.8015159499121 }, 
					{ lat: -41.234671607461905, lng: 174.80452002376524 }, 
					{ lat: -41.229830473346134, lng: 174.8119872930574 }, 
					{ lat: -41.221954795981645, lng: 174.8137897373693 }, 
					{ lat: -41.216983593308754, lng: 174.80958403397486 }, 
					{ lat: -41.21491752780786, lng: 174.81087149419764 }, 
					{ lat: -41.21285139704945, lng: 174.8032325635425 }, 
					{ lat: -41.2135616368582, lng: 174.79516447947972 }, 
					{ lat: -41.220857290231265, lng: 174.78718222609848 }, 
				]
			},
			{
				name: "Hataitai",
				id: 13,
				coords: [
					{ lat: -41.29580939759352, lng: 174.79562158954462 },
					{ lat: -41.303395840451536, lng: 174.78897157202778 },
					{ lat: -41.311906408788545, lng: 174.78884842355524 },
					{ lat: -41.31227640829931, lng: 174.79722251968752 },
					{ lat: -41.30580111367781, lng: 174.79943919219318 },
					{ lat: -41.303210815759286, lng: 174.80608920971002 },
					{ lat: -41.297012185201055, lng: 174.8044882795671 },
					{ lat: -41.29580939759352, lng: 174.79562158954462 },
				]
			},
			{
				name: "Horokiwi",
				id: 14,
				coords: [
					{ lat: -41.223800582265405, lng: 174.85701179008237},
					{ lat: -41.18713029689266, lng: 174.8679239226758},
					{ lat: -41.18521412019348, lng: 174.85446562581055},
					{ lat: -41.193425911237036, lng: 174.8424622799578},
					{ lat: -41.23063977870019, lng: 174.8402798534391},	
					{ lat: -41.223800582265405, lng: 174.85701179008237},
				],
			},
			{
				name: "Houghton Bay",
				id: 15,
				coords: [
					{ lat: -41.330081455766454, lng: 174.78270970828942},
					{ lat: -41.33011368164154, lng: 174.78524171350153},
					{ lat: -41.33204720497533, lng: 174.7869583272047},
					{ lat: -41.331950530171554, lng: 174.7891470096762},
					{ lat: -41.33742854277449, lng: 174.79150735351803},
					{ lat: -41.34719115232303, lng: 174.7915931842032},
					{ lat: -41.34606353291575, lng: 174.787129988575},
					{ lat: -41.34490367543784, lng: 174.78798829542657},
					{ lat: -41.34232614040759, lng: 174.78524171350153},
					{ lat: -41.34519364174422, lng: 174.7821088934933},
					{ lat: -41.34590244283484, lng: 174.77820359731862},
					{ lat: -41.33559184870953, lng: 174.78481256007578},
					{ lat: -41.330017003968486, lng: 174.78279553897454},
					{ lat: -41.330081455766454, lng: 174.78270970828942},
				]
			},
			{
				name: "Island Bay",
				id: 16,
				coords: [
					{ lat: -41.348100170587756, lng: 174.75928035373337 },
					{ lat: -41.34800501279907, lng: 174.76840685735377 },
					{ lat: -41.34324694604454, lng: 174.77309686615868 },
					{ lat: -41.34572118414821, lng: 174.77804038895306 },
					{ lat: -41.340582276793526, lng: 174.77994174387396 },
					{ lat: -41.32849472961229, lng: 174.77994174387396 },
					{ lat: -41.32801879603606, lng: 174.76473090450665 },
					{ lat: -41.348100170587756, lng: 174.75928035373337 },				
				]
			},
			{
				name: "Kaiwharawhara",
				id: 17,
				coords: [
					{ lat: -41.2571689530928, lng: 174.78245523650278 },
					{ lat: -41.26003279706181, lng: 174.78700561105663 },
					{ lat: -41.26249878430046, lng: 174.7858415617522 },
					{ lat: -41.26305560724164, lng: 174.78806383769708 },
					{ lat: -41.255259654017834, lng: 174.80076255738228 },
					{ lat: -41.2533502991336, lng: 174.79335497089923 },
					{ lat: -41.25629386461119, lng: 174.7835134631432 },
				]
			},			
			{		
				name: "Karaka Bays",
				id: 18,
				coords: [
					{ lat: -41.311792633884835, lng: 174.82752360412584 },
					{ lat: -41.30805666603754, lng: 174.82921676675056 },
					{ lat: -41.29788102987366, lng: 174.82847600810226 },
					{ lat: -41.29493933922421, lng: 174.8311215747033 },
					{ lat: -41.29637044854408, lng: 174.83503701327297 },
					{ lat: -41.307659210050595, lng: 174.83260309199994 },
					{ lat: -41.31131571373982, lng: 174.83027499339101 },
					{ lat: -41.31187212023647, lng: 174.8274177814618},
				]
			},
			{		
				name: "Karori",
				id: 19,
				coords: [
					{ lat: -41.264359127463834, lng: 174.74075168836728 },
					{ lat: -41.26458088651925, lng: 174.7404566678421 },
					{ lat: -41.28497949777969, lng: 174.71095461532576 },
					{ lat: -41.29207317266526, lng: 174.71478988215287 },
					{ lat: -41.30027427280439, lng: 174.69974383536953 },
					{ lat: -41.314235935522234, lng: 174.69443346591657 },
					{ lat: -41.316008631150495, lng: 174.7026940406212 },
					{ lat: -41.316008631150495, lng: 174.73337617523816 },
					{ lat: -41.3106903996393, lng: 174.74576703729502 },
					{ lat: -41.291186505482635, lng: 174.75432263252478 },
					{ lat: -41.27234197900367, lng: 174.75343757094927 },				
				]
			},
			{		
				name: "Kelburn",
				id: 20, 
				coords: [
					{ lat: -41.27794065417185, lng: 174.7731735056893 },
					{ lat: -41.27882750135849, lng: 174.7691169734683 },
					{ lat: -41.28592184503678, lng: 174.75827496916855 },
					{ lat: -41.286586900215696, lng: 174.754587212604 },
					{ lat: -41.28957956463617, lng: 174.75355464076594 },
					{ lat: -41.291740848016104, lng: 174.75746366272435 },
					{ lat: -41.2929045863332, lng: 174.76860068754928 },
					{ lat: -41.27794065417185, lng: 174.77309975055803 },				
				]
			},
			{		
				name: "Kilbirnie",
				id: 21,
				coords: [
					{ lat: -41.31316525235128, lng: 174.789329828603 },
					{ lat: -41.315986391100004, lng: 174.7908691845842 },
					{ lat: -41.3191773680925, lng: 174.78852936349278 },
					{ lat: -41.32079591989023, lng: 174.78957612556 },
					{ lat: -41.32320055119384, lng: 174.79844281601171 },
					{ lat: -41.32135084268175, lng: 174.7993664296004 },
					{ lat: -41.321674545461356, lng: 174.80145995373488 },
					{ lat: -41.31691132804581, lng: 174.80386134906553 },
					{ lat: -41.31260422376738, lng: 174.79712030301505 },
					{ lat: -41.31194063900192, lng: 174.79022933700722 },
					{ lat: -41.31316525235128, lng: 174.789329828603 },				
				]
			},
			{		
				name: "Kingston",
				id: 22,
				coords: [
					{ lat: -41.320073330849795, lng: 174.76115436206277 },
					{ lat: -41.3219012601785, lng: 174.76036069201032 },
					{ lat: -41.32448411638491, lng: 174.76004322398938 },
					{ lat: -41.32798074320664, lng: 174.75835006121093 },
					{ lat: -41.32897406887332, lng: 174.76427613093549 },
					{ lat: -41.32468279339125, lng: 174.76427613093549 },
					{ lat: -41.32468279339125, lng: 174.76877359456574 },
					{ lat: -41.321980734203656, lng: 174.76903815124987 },
					{ lat: -41.321583363108644, lng: 174.7654930916825 },
					{ lat: -41.31951699435577, lng: 174.76316499286216 },
					{ lat: -41.320033592686286, lng: 174.76115436206277 },	
					{ lat: -41.320073330849795, lng: 174.76115436206277 }			
				]
			},
			{		
				name: "Lyall Bay",
				id: 23,
				coords: [
					{ lat: -41.323982002509155, lng: 174.7912954416408 },
					{ lat: -41.32694139143315, lng: 174.79184960979404 },
					{ lat: -41.32962322154781, lng: 174.78809358119992 },
					{ lat: -41.33794543969197, lng: 174.7912954416408 },
					{ lat: -41.34030320819775, lng: 174.78833987815688 },
					{ lat: -41.3470062013497, lng: 174.79148016435855 },
					{ lat: -41.34548075320933, lng: 174.79252692642575 },
					{ lat: -41.3412277999984, lng: 174.79283479762202 },
					{ lat: -41.340488127608005, lng: 174.79375841121072 },
					{ lat: -41.33854644761279, lng: 174.79388155968923 },
					{ lat: -41.337020801292155, lng: 174.79283479762202 },
					{ lat: -41.33276729558582, lng: 174.79135701588007 },
					{ lat: -41.32869846501235, lng: 174.79800703371885 },
					{ lat: -41.32832855872291, lng: 174.8013936168775 },
					{ lat: -41.328929655376776, lng: 174.80471862579688 },
					{ lat: -41.32920708273118, lng: 174.80521121971088 },
					{ lat: -41.32888341736952, lng: 174.80558066514632 },
					{ lat: -41.32828232028906, lng: 174.80379501220816 },
					{ lat: -41.32768121766293, lng: 174.80145519111673 },
					{ lat: -41.32795865033333, lng: 174.79868435035056 },
					{ lat: -41.32379703623867, lng: 174.800531577528 },
					{ lat: -41.32171612951836, lng: 174.79338896577522 },
					{ lat: -41.323982002509155, lng: 174.7912954416408 }					
				]
			},
			{		
				name: "Maupuia",
				id: 24,
				coords: [
					{ lat: -41.3123232857973, lng: 174.8116646406342 },
					{ lat: -41.31008063246983, lng: 174.81189110322384 },
					{ lat: -41.31221022749099, lng: 174.8116826350776 },
					{ lat: -41.3122415445458, lng: 174.8144761082372 },
					{ lat: -41.30876525951618, lng: 174.81872885839164 },
					{ lat: -41.30193742160225, lng: 174.823773787513 },
					{ lat: -41.302313284480896, lng: 174.82552491994144 },
					{ lat: -41.30071585228555, lng: 174.82669234156035 },
					{ lat: -41.300026751726456, lng: 174.82881871664802 },
					{ lat: -41.29701968261468, lng: 174.82911057205274 },
					{ lat: -41.297144979929016, lng: 174.8300278318962 },
					{ lat: -41.29488959144456, lng: 174.8311118662566 },
					{ lat: -41.296894385059645, lng: 174.83569816547387 },
					{ lat: -41.29564139627105, lng: 174.8369489743513 },
					{ lat: -41.29376186795802, lng: 174.8358232463616 },
					{ lat: -41.29285340986003, lng: 174.83298807957277 },
					{ lat: -41.291976265898874, lng: 174.8329463859435 },
					{ lat: -41.28984601003533, lng: 174.83302977320201 },
					{ lat: -41.28912546657425, lng: 174.83440566296719 },
					{ lat: -41.288749527696396, lng: 174.83386364578698 },
					{ lat: -41.288216943911365, lng: 174.82923565294044 },
					{ lat: -41.28398744774992, lng: 174.8259835498592 },
					{ lat: -41.284595579418735, lng: 174.82505422816703 },
					{ lat: -41.291625345877016, lng: 174.82102018576933 },
					{ lat: -41.29878332048184, lng: 174.819561064051 },
					{ lat: -41.29897676835337, lng: 174.8175011275075 },
					{ lat: -41.2995571085253, lng: 174.81690031268232 },
					{ lat: -41.302071856264185, lng: 174.8181019423327 },
					{ lat: -41.30400621161694, lng: 174.81518369889605 },				
				]
			},
			{		
				name: "Melrose",
				id: 25,
				coords: [
					{ lat: -41.31930490621097, lng: 174.78873524184405 },
					{ lat: -41.32216083740589, lng: 174.78639023732808 },
					{ lat: -41.32349356246643, lng: 174.7866437513298 },
					{ lat: -41.32344596561218, lng: 174.78455226081556 },
					{ lat: -41.32454068446459, lng: 174.7832846908069 },
					{ lat: -41.32687285025866, lng: 174.78575645232374 },
					{ lat: -41.32839585225149, lng: 174.7851860458199 },
					{ lat: -41.32939530296203, lng: 174.78645361582852 },
					{ lat: -41.329966410770055, lng: 174.78550293832205 },
					{ lat: -41.3318224765711, lng: 174.7872141578337 },
					{ lat: -41.33163211328157, lng: 174.78867186334364 },
					{ lat: -41.329490487944355, lng: 174.788291592341 },
					{ lat: -41.32687285025866, lng: 174.79184078836522 },
					{ lat: -41.324588280519244, lng: 174.7913971388622 },
					{ lat: -41.321922847919815, lng: 174.79298160137301 },
					{ lat: -41.31930490621097, lng: 174.78873524184405 }				
				]
			},
			{		
				name: "Miramar",
				id: 26,
				coords: [
					{ lat: -41.3123694675306, lng: 174.81180881646966 },
					{ lat: -41.315946225756676, lng: 174.80937489497563 },
					{ lat: -41.325642227413795, lng: 174.80895160428108 },
					{ lat: -41.32556275785409, lng: 174.81794653154154 },
					{ lat: -41.32389387471106, lng: 174.8233434878978 },
					{ lat: -41.32460911557756, lng: 174.82694145880203 },
					{ lat: -41.323575987361544, lng: 174.82789386286493 },
					{ lat: -41.31984069484534, lng: 174.82355513324512 },
					{ lat: -41.30966689782327, lng: 174.82874044425412 },
					{ lat: -41.30020702158983, lng: 174.8290579122751 },
					{ lat: -41.30171751406379, lng: 174.8240842466134 },
					{ lat: -41.3123694675306, lng: 174.81180881646966 },				
				]
			},
			{		
				name: "Mornington",
				id: 27,
				coords: [
					{ lat: -41.31631917051367, lng: 174.76947331887934 },
					{ lat: -41.31614811347474, lng: 174.76863823928886 },
					{ lat: -41.31586301741228, lng: 174.76279268215575 },
					{ lat: -41.31740252133548, lng: 174.7637036780726 },
					{ lat: -41.31797269874605, lng: 174.7614261882805 },
					{ lat: -41.319968280402996, lng: 174.76127435562765 },
					{ lat: -41.320595450589906, lng: 174.7636277617462 },
					{ lat: -41.32162171605891, lng: 174.76560158623272 },
					{ lat: -41.321678730333325, lng: 174.76886598826812 },
					{ lat: -41.31631917051367, lng: 174.76947331887934 }
				]
			},
			{		
				name: "Mount Cook",
				id: 28,
				coords: [
					{ lat: -41.29729209363953, lng: 174.77016862738105 },
					{ lat: -41.297315223853815, lng: 174.76955288499974 },
					{ lat: -41.297939736538964, lng: 174.76875241990402 },
					{ lat: -41.30016017765818, lng: 174.76853691007054 },
					{ lat: -41.30069214711771, lng: 174.76918343957092 },
					{ lat: -41.302288029461025, lng: 174.7697068205951 },
					{ lat: -41.30316690451022, lng: 174.76927580092814 },
					{ lat: -41.304577705478884, lng: 174.76927580092814 },
					{ lat: -41.30534091295251, lng: 174.76770565785577 },
					{ lat: -41.305734076952724, lng: 174.76755172226044 },
					{ lat: -41.30584971297222, lng: 174.76992233042853 },
					{ lat: -41.30994309593828, lng: 174.76964524635696 },
					{ lat: -41.31031310659189, lng: 174.77010705314294 },
					{ lat: -41.31028998098757, lng: 174.77226215147763 },
					{ lat: -41.30839365351792, lng: 174.7735859975975 },
					{ lat: -41.307445469099335, lng: 174.77392465590722 },
					{ lat: -41.30746859571263, lng: 174.77546401186058 },
					{ lat: -41.30723732921058, lng: 174.7779269813859 },
					{ lat: -41.30619661980028, lng: 174.7782964268147 },
					{ lat: -41.30626600094441, lng: 174.77937397598205 },
					{ lat: -41.30152478625618, lng: 174.7810056932926 },
					{ lat: -41.29995201451457, lng: 174.78199088110273 },
					{ lat: -41.29796286652358, lng: 174.77586424440844 },
					{ lat: -41.298402334672765, lng: 174.77549479897962 },
					{ lat: -41.296760096447066, lng: 174.7704149243336 },
					{ lat: -41.29729209363953, lng: 174.77016862738105 }				]
			},
			{		
				name: "Mount Victoria",
				id: 29,
				coords: [
					{ lat: -41.2912529797756, lng: 174.79124268025907 },
					{ lat: -41.2911202199041, lng: 174.79133102597712 },
					{ lat: -41.29317796755245, lng: 174.78417502281516 },
					{ lat: -41.29935082104911, lng: 174.78055284837518 },
					{ lat: -41.29988179191193, lng: 174.78196637986397 },
					{ lat: -41.30412940319269, lng: 174.7809062312474 },
					{ lat: -41.30631946965366, lng: 174.78850396299958 },
					{ lat: -41.302868422469025, lng: 174.7891223830259 },
					{ lat: -41.296961398671904, lng: 174.7948648546991 },
					{ lat: -41.29390812047072, lng: 174.7945114718269 },
					{ lat: -41.2912529797756, lng: 174.79124268025907 }				
				]
			},
			{		
				name: "Newlands",
				id: 30,
				coords: [
					{ lat: -41.2364681453659, lng: 174.80631217534253 },
					{ lat: -41.22974159101052, lng: 174.8119667313113 },
					{ lat: -41.22007578981481, lng: 174.8151538446755 },
					{ lat: -41.22200906435373, lng: 174.82450956455108 },
					{ lat: -41.21907046446931, lng: 174.8253320454193 },
					{ lat: -41.219689128049154, lng: 174.83078098117096 },
					{ lat: -41.216982431683114, lng: 174.84219290321698 },
					{ lat: -41.218993131110366, lng: 174.84383786495337 },
					{ lat: -41.22487020579065, lng: 174.8430153840852 },
					{ lat: -41.22517951088035, lng: 174.83766925844202 },
					{ lat: -41.22981891165931, lng: 174.84085637180624 },
					{ lat: -41.235463072033, lng: 174.83324842377553 },
					{ lat: -41.23963789080109, lng: 174.82687419704712 },
					{ lat: -41.2345352983167, lng: 174.82039716021018 },
					{ lat: -41.23639083258126, lng: 174.81422855369883 },
					{ lat: -41.24164789362248, lng: 174.81145268076867 },
					{ lat: -41.2364681453659, lng: 174.80631217534253 }
				]
			},
			{		
				name: "Newtown",
				id: 31,
				coords: [
					{ lat: -41.30394022927989, lng: 174.78016158216752 },
					{ lat: -41.30613030209445, lng: 174.77839466780657 },
					{ lat: -41.30772303612198, lng: 174.77371234474995 },
					{ lat: -41.30964753782923, lng: 174.77212212182505 },
					{ lat: -41.315553412516145, lng: 174.77185708467093 },
					{ lat: -41.31834026867779, lng: 174.77830632208853 },
					{ lat: -41.32424535581016, lng: 174.78237022511874 },
					{ lat: -41.323581889556436, lng: 174.78643412814898 },
					{ lat: -41.31608425144788, lng: 174.7907630683334 },
					{ lat: -41.30652848924881, lng: 174.78837773394608 },
					{ lat: -41.303741128103844, lng: 174.78024992788556 },
					{ lat: -41.30394022927989, lng: 174.78016158216752 }
				]
			},
			{		
				name: "Ngaio",
				id: 32,
				coords: [
					{ lat: -41.23713305143353, lng: 174.77375557478632 },
					{ lat: -41.239633369872244, lng: 174.76476573585614 },
					{ lat: -41.2419483942539, lng: 174.76156387541525 },
					{ lat: -41.24454112420555, lng: 174.7627953602002 },
					{ lat: -41.24824484569211, lng: 174.75836201497432 },
					{ lat: -41.252596450177506, lng: 174.75983979671628 },
					{ lat: -41.253151953291514, lng: 174.76414999346363 },
					{ lat: -41.25426294534839, lng: 174.76735185390456 },
					{ lat: -41.26009534364999, lng: 174.769937971953 },
					{ lat: -41.25778096220739, lng: 174.77868151392616 },
					{ lat: -41.258521573191814, lng: 174.78151392893162 },
					{ lat: -41.25648487277729, lng: 174.78348430458752 },
					{ lat: -41.24426333663103, lng: 174.78052874110364 },
					{ lat: -41.23944816437915, lng: 174.7775731776197 },
					{ lat: -41.23713305143353, lng: 174.77375557478632 }
				]
			},
			{		
				name: "Ngauranga",
				id: 33,
				coords: [
					{ lat: -41.24905043143685, lng: 174.81313923913012 }, 
					{ lat: -41.234593694663204, lng: 174.82146481599347 }, 
					{ lat: -41.23465824077314, lng: 174.819147387382 }, 
					{ lat: -41.23640096165003, lng: 174.81434086878053 }, 
					{ lat: -41.239950804899976, lng: 174.81142262534388 }, 
					{ lat: -41.23614278371115, lng: 174.80644444536375 }, 
					{ lat: -41.23323821163199, lng: 174.80850438190726 }, 
					{ lat: -41.23207634666335, lng: 174.80695942949964 }, 
					{ lat: -41.234980970366834, lng: 174.80464200088818 }, 
					{ lat: -41.232915473434254, lng: 174.80180958814088 }, 
					{ lat: -41.233367306464956, lng: 174.80077961986913 }, 
					{ lat: -41.236013694359315, lng: 174.799921312976 }, 
					{ lat: -41.2376272929337, lng: 174.802667895034 }, 
					{ lat: -41.239950804899976, lng: 174.79837636056837 },
					{ lat: -41.24905043143685, lng: 174.81313923913012 },
				]
			},
			{		
				name: "Northland",
				id: 34,
				coords: [
					{ lat: -41.27038022838497, lng: 174.76613736610918 },
					{ lat: -41.271928469059375, lng: 174.76369119146375 },
					{ lat: -41.271638176729724, lng: 174.76073003268246 },
					{ lat: -41.272638067097624, lng: 174.75836968872636 },
					{ lat: -41.27360568835585, lng: 174.75828385803706 },
					{ lat: -41.27444428184468, lng: 174.75905633424088 },
					{ lat: -41.27518610556618, lng: 174.75901341889625 },
					{ lat: -41.27515385253621, lng: 174.7575542971779 },
					{ lat: -41.27441202844816, lng: 174.75665307494012 },
					{ lat: -41.274508788589905, lng: 174.75575185270236 },
					{ lat: -41.27579891010791, lng: 174.75626683683822 },
					{ lat: -41.28018513254045, lng: 174.75420690029472 },
					{ lat: -41.283087617661565, lng: 174.75339150874626 },
					{ lat: -41.28476455022554, lng: 174.75369191615883 },
					{ lat: -41.286925149803395, lng: 174.75472188443058 },
					{ lat: -41.28750559719948, lng: 174.7560951754596 },
					{ lat: -41.28711863284249, lng: 174.75815511200312 },
					{ lat: -41.2860222213681, lng: 174.75832677338173 },
					{ lat: -41.28076563989178, lng: 174.76673818093437 },
					{ lat: -41.279185357775845, lng: 174.76639485817714 },
					{ lat: -41.271896214419826, lng: 174.76815438730802 },				
					{ lat: -41.27038022838497, lng: 174.76613736610918 },
				]
			},
			{		
				name: "Oriental Bay",
				id: 35,
				coords: [
					{ lat: -41.29137288027809, lng: 174.78633377704278 },
					{ lat: -41.291480558802455, lng: 174.78647708799147 },
					{ lat: -41.29325722879485, lng: 174.78447073470954 },
					{ lat: -41.29358025450397, lng: 174.7850439785044 },
					{ lat: -41.292288142070085, lng: 174.78647708799147 },
					{ lat: -41.2919651099627, lng: 174.7876235755811 },
					{ lat: -41.29177667382805, lng: 174.78966575660021 },
					{ lat: -41.291480558802455, lng: 174.79056145002963 },
					{ lat: -41.2919651099627, lng: 174.79109886608725 },
					{ lat: -41.29301495846329, lng: 174.79134966024753 },
					{ lat: -41.294387811778634, lng: 174.79342766900373 },
					{ lat: -41.293741766758686, lng: 174.794287534696 },
					{ lat: -41.29446856695625, lng: 174.79478912301647 },
					{ lat: -41.294656995315236, lng: 174.7959356106061 },
					{ lat: -41.29387635999882, lng: 174.79640137118943 },
					{ lat: -41.2922343034966, lng: 174.79851520768287 },
					{ lat: -41.287415571226035, lng: 174.80066487191348 },
					{ lat: -41.28900390777696, lng: 174.7973687200932 },
					{ lat: -41.290726805399615, lng: 174.79632971571507 },
					{ lat: -41.291130602948456, lng: 174.79314104710636 },
					{ lat: -41.29051144568497, lng: 174.7912063492988 },
					{ lat: -41.290753725313955, lng: 174.79034648360656 },
					{ lat: -41.289838441994654, lng: 174.78955827338868 },
					{ lat: -41.29040376556105, lng: 174.78912834054256 },
					{ lat: -41.290969084228834, lng: 174.7894866179143 },
					{ lat: -41.29137288027809, lng: 174.78633377704278 },
				]
			},
			{		
				name: "Ōwhiro Bay",
				id: 36,
				coords: [
					{ lat: -41.311613028008885, lng: 174.74129500662727 }, 
					{ lat: -41.31398117937779, lng: 174.74029182998635 },
					{ lat: -41.3192553891443, lng: 174.7335562153971 }, 
					{ lat: -41.32625113128653, lng: 174.7336995263458 }, 
					{ lat: -41.3300177582909, lng: 174.7269639117566 }, 
					{ lat: -41.34110114012514, lng: 174.7111997073988 }, 
					{ lat: -41.35917484237728, lng: 174.7134926825781 }, 
					{ lat: -41.36197151460631, lng: 174.71650221250096 }, 
					{ lat: -41.35809916721131, lng: 174.72567411321822 }, 
					{ lat: -41.34906279394301, lng: 174.73412945919193 }, 
					{ lat: -41.348847626909716, lng: 174.75333312631864 }, 
					{ lat: -41.34615797899482, lng: 174.76035536280529 }, 
					{ lat: -41.33604390856536, lng: 174.76580117885615 }, 					
					{ lat: -41.32904921785891, lng: 174.76436806936908 }, 
					{ lat: -41.32797304493629, lng: 174.75877894236953 }, 
					{ lat: -41.32452917213112, lng: 174.75978211901048 }, 
					{ lat: -41.32291479409487, lng: 174.75275988252383 }, 
					{ lat: -41.31042892006371, lng: 174.74717075552422 }, 
					{ lat: -41.311613028008885, lng: 174.74129500662727 }
				]
			},	
			{ 
				name: "Paparangi",
				id: 37,
				coords: [
					{ lat: -41.210218097170966, lng: 174.81556424740018 },
					{ lat: -41.22009659260671, lng: 174.81028566000745 },
					{ lat: -41.22058079422236, lng: 174.81303224206545 },
					{ lat: -41.22145234809682, lng: 174.81294641137612 },
					{ lat: -41.22167830461275, lng: 174.81462010981775 },
					{ lat: -41.22006431237155, lng: 174.81534967067688 },
					{ lat: -41.22016115302925, lng: 174.816336723604 },
					{ lat: -41.221194111122664, lng: 174.81749543790968 },
					{ lat: -41.22206565682376, lng: 174.82449063908868 },
					{ lat: -41.21241344730039, lng: 174.82689389838941 },
					{ lat: -41.211703195025876, lng: 174.82135781892876 },
					{ lat: -41.212800854381854, lng: 174.820327850657 },
					{ lat: -41.212639435043386, lng: 174.81891164428333 },
					{ lat: -41.21012124179205, lng: 174.81565007808948 },
					{ lat: -41.210218097170966, lng: 174.81556424740018 },
				]
			},
			{ 
				name: "Pipitea",
				id: 38,
				coords: [
					{ lat: -41.25942687657027, lng: 174.79284127001597 },
					{ lat: -41.26288022179287, lng: 174.78789390980523 },
					{ lat: -41.26261458633575, lng: 174.7859503040082 },
					{ lat: -41.267395859243386, lng: 174.7805612152072 },
					{ lat: -41.27005197069449, lng: 174.7805612152072 },
					{ lat: -41.27456711217334, lng: 174.78321158674868 },
					{ lat: -41.27556305722327, lng: 174.78003114089893 },
					{ lat: -41.27728932597958, lng: 174.77967775802674 },
					{ lat: -41.27722293187163, lng: 174.7731401748911 },
					{ lat: -41.278019656709674, lng: 174.77287513773695 },
					{ lat: -41.27934750982783, lng: 174.77685069504915 },
					{ lat: -41.28200313501893, lng: 174.77905933800037 },
					{ lat: -41.28160479812915, lng: 174.7843600810833 },
					{ lat: -41.280940897909794, lng: 174.79001420703844 },
					{ lat: -41.26527089228342, lng: 174.7863036868804 },
					{ lat: -41.26221613112409, lng: 174.79125104709112 },
					{ lat: -41.26208331217995, lng: 174.79301796145208 },
					{ lat: -41.261219982458655, lng: 174.79381307291453 },
					{ lat: -41.25942687657027, lng: 174.79284127001597 },
				]
			},
			{ 
				name: "Rongotai",
				id: 39,
				coords: [
					{ lat: -41.31663511472057, lng: 174.80384945433505 },
					{ lat: -41.31679627718804, lng: 174.8038923696797 },
					{ lat: -41.317440923073, lng: 174.80402111571368 },
					{ lat: -41.317956635190406, lng: 174.80333447019916 },
					{ lat: -41.31876242720482, lng: 174.80402111571368 },
					{ lat: -41.3192136663814, lng: 174.80239033261674 },
					{ lat: -41.32156650575711, lng: 174.80153202572362 },
					{ lat: -41.3213086644904, lng: 174.79938625849078 },
					{ lat: -41.323145761255745, lng: 174.79852795159766 },
					{ lat: -41.3237903443383, lng: 174.80041622676256 },
					{ lat: -41.328012205874124, lng: 174.79878544366562 },
					{ lat: -41.328366700882064, lng: 174.8038065389904 },
					{ lat: -41.329075685111626, lng: 174.80556606812127 },
					{ lat: -41.33616510307165, lng: 174.80470776122817 },
					{ lat: -41.336841779902656, lng: 174.80659603639305 },
					{ lat: -41.33639066279641, lng: 174.8078405813881 },
					{ lat: -41.33677733479306, lng: 174.80917095707244 },
					{ lat: -41.335971765543, lng: 174.81071590948005 },
					{ lat: -41.33448949209157, lng: 174.8106729941354 },
					{ lat: -41.33407058261158, lng: 174.81243252326632 },
					{ lat: -41.32778661713757, lng: 174.81311916878082 },
					{ lat: -41.32591742327305, lng: 174.81131672430527 },
					{ lat: -41.3258529673558, lng: 174.8089992956938 },
					{ lat: -41.31599046086589, lng: 174.80942844914037 },
					{ lat: -41.31679627718804, lng: 174.80513691467476 },
					{ lat: -41.31663511472057, lng: 174.80384945433505 }
				]
			},
			{ 
				name: "Roseneath",
				id: 40,
				coords: [
					{ lat: -41.285728774134135, lng: 174.80340334500917 },
					{ lat: -41.28584164137945, lng: 174.8031243952689 },
					{ lat: -41.28653496446036, lng: 174.80192276561849 },
					{ lat: -41.287341144825795, lng: 174.80074259364045 },
					{ lat: -41.287728107863124, lng: 174.8003348978662 },
					{ lat: -41.29216189552542, lng: 174.79859682640765 },
					{ lat: -41.29393532622064, lng: 174.7962793977962 },
					{ lat: -41.29545076515732, lng: 174.79548546392007 },
					{ lat: -41.29636968438663, lng: 174.79730936606796 },
					{ lat: -41.29638580566107, lng: 174.79859682640765 },
					{ lat: -41.29611174345373, lng: 174.79969116769638 },
					{ lat: -41.2966759879196, lng: 174.80187985027385 },
					{ lat: -41.296321320539406, lng: 174.80220171535876 },
					{ lat: -41.29712737997796, lng: 174.80451914397023 },
					{ lat: -41.29556361558451, lng: 174.80542036620798 },
					{ lat: -41.29454795471111, lng: 174.80368229474942 },
					{ lat: -41.29335493602766, lng: 174.8037466677664 },
					{ lat: -41.29201679451669, lng: 174.80271669949465 },
					{ lat: -41.291662101803354, lng: 174.80400415983434 },
					{ lat: -41.29113005911748, lng: 174.8041329058683 },
					{ lat: -41.289066337341396, lng: 174.80391832914503 },
					{ lat: -41.28648659332168, lng: 174.8042831095746 },
					{ lat: -41.285728774134135, lng: 174.80340334500917 },
				]
			},
			{ 
				name: "Seatoun",
				id: 41,
				coords: [
					{ lat: -41.31072068255346, lng: 174.82873285088607 },
					{ lat: -41.310752918003416, lng: 174.82864702019677 },
					{ lat: -41.31107527162646, lng: 174.82804620537155 },
					{ lat: -41.31265478133938, lng: 174.82577169210478 },
					{ lat: -41.31494339077768, lng: 174.82461297779906 },
					{ lat: -41.31984267887123, lng: 174.82328260211472 },
					{ lat: -41.3206484475677, lng: 174.82461297779906 },
					{ lat: -41.32313015255736, lng: 174.82590043813872 },
					{ lat: -41.323323528197925, lng: 174.8273166445124 },
					{ lat: -41.32535393777832, lng: 174.82830369743954 },
					{ lat: -41.32767432840147, lng: 174.8287757662307 },
					{ lat: -41.32909230421398, lng: 174.83062112605094 },
					{ lat: -41.32954347186438, lng: 174.83718717378335 },
					{ lat: -41.32574067528729, lng: 174.8388179568803 },
					{ lat: -41.323323528197925, lng: 174.83684385102612 },
					{ lat: -41.321035213003384, lng: 174.83345353879824 },
					{ lat: -41.31790879335364, lng: 174.828990342954 },
					{ lat: -41.31571698706492, lng: 174.82796037468225 },
					{ lat: -41.31352510707255, lng: 174.828990342954 },
					{ lat: -41.312687015833006, lng: 174.83053529536164 },
					{ lat: -41.31072068255346, lng: 174.82873285088607 }
				]
			},
			{ 
				name: "Southgate",
				id: 42,
				coords: [
					{ lat: -41.32996451603181, lng: 174.78148890899658 },
					{ lat: -41.33006402665219, lng: 174.78148890899658 },
					{ lat: -41.33182202255003, lng: 174.7819306375868 },
					{ lat: -41.33208737630064, lng: 174.78091466182923 },
					{ lat: -41.3334141288408, lng: 174.77928026604533 },
					{ lat: -41.33500619622044, lng: 174.77932443890438 },
					{ lat: -41.33716205877344, lng: 174.78104718040632 },
					{ lat: -41.33842237613769, lng: 174.78003120464876 },
					{ lat: -41.340312806457355, lng: 174.77998703178974 },
					{ lat: -41.3409761023369, lng: 174.7762765116317 },
					{ lat: -41.34429248039972, lng: 174.7762765116317 },
					{ lat: -41.34482308521653, lng: 174.77830846314677 },
					{ lat: -41.3446241089169, lng: 174.77928026604533 },
					{ lat: -41.34339707496711, lng: 174.78034041466194 },
					{ lat: -41.342335837770165, lng: 174.7802520689439 },
					{ lat: -41.34183837688065, lng: 174.7818864647278 },
					{ lat: -41.33845554205479, lng: 174.78210732902292 },
					{ lat: -41.336963059065546, lng: 174.7833441690756 },
					{ lat: -41.33557004408644, lng: 174.78489021914143 },
					{ lat: -41.33457501514728, lng: 174.7849785648595 },
					{ lat: -41.33324828625103, lng: 174.7845368362692 },
					{ lat: -41.33165617590702, lng: 174.7830349590624 },
					{ lat: -41.330163537120576, lng: 174.78299078620338 },
					{ lat: -41.32996451603181, lng: 174.78148890899658 },
				]
			},
			{ 
				name: "Strathmore Park",
				id: 43,
				coords: [
					{ lat: -41.32583257648269, lng: 174.81136044229942 },
					{ lat: -41.32647713298772, lng: 174.81123169626542 },
					{ lat: -41.327701772781225, lng: 174.81307705608563 },
					{ lat: -41.33388907451178, lng: 174.81230457988184 },
					{ lat: -41.3343724327118, lng: 174.81075962747423 },
					{ lat: -41.33524246843345, lng: 174.81127461161012 },
					{ lat: -41.3356935934919, lng: 174.8131199714303 },
					{ lat: -41.33949580912265, lng: 174.8117466804013 },
					{ lat: -41.341751255875984, lng: 174.8127337333284 },
					{ lat: -41.34275007162506, lng: 174.817025267794 },
					{ lat: -41.34200901624557, lng: 174.82144554829364 },
					{ lat: -41.33968913619078, lng: 174.8229475853566 },
					{ lat: -41.336595834235595, lng: 174.82281883932265 },
					{ lat: -41.335178021741484, lng: 174.82269009328866 },
					{ lat: -41.33282567384675, lng: 174.82410629966236 },
					{ lat: -41.33156890522566, lng: 174.8243637917303 },
					{ lat: -41.328926389557026, lng: 174.82680996637566 },
					{ lat: -41.33037656386509, lng: 174.8285694955066 },
					{ lat: -41.33047324100458, lng: 174.82942780239972 },
					{ lat: -41.32918420068208, lng: 174.8311873315306 },
					{ lat: -41.327830680894195, lng: 174.82895573360847 },
					{ lat: -41.326992773600985, lng: 174.82989987119092 },
					{ lat: -41.324672358706046, lng: 174.82895573360847 },
					{ lat: -41.323479891130845, lng: 174.8283549187833 },
					{ lat: -41.32444675840606, lng: 174.8271103737883 },
					{ lat: -41.3239633265617, lng: 174.82329090811388 },
					{ lat: -41.32512355696284, lng: 174.81801232072112 },
					{ lat: -41.32583257648269, lng: 174.81136044229942 },
				]
			},
			{ 
				name: "Takapu Valley",
				id: 44,
				coords: [
					{ lat: -41.14749524292971, lng: 174.8831556383354 }, 
					{ lat: -41.15111453893097, lng: 174.88023739489878 }, 
					{ lat: -41.149821956151314, lng: 174.87594586043318 }, 
					{ lat: -41.1558968736344, lng: 174.87079601907442 }, 
					{ lat: -41.15421663362867, lng: 174.86907940528815 }, 
					{ lat: -41.1553798812967, lng: 174.8620412887645 }, 
					{ lat: -41.16210046364121, lng: 174.85706310878436 }, 
					{ lat: -41.16016190483849, lng: 174.85362988121187 }, 
					{ lat: -41.170241784441565, lng: 174.84247189160124 }, 
					{ lat: -41.17941571138939, lng: 174.8368070661066 }, 
					{ lat: -41.18174129153725, lng: 174.85431652672636 }, 
					{ lat: -41.18432517262758, lng: 174.85500317224088 }, 
					{ lat: -41.18600464066549, lng: 174.86839275977366 }, 
					{ lat: -41.17773607437066, lng: 174.87903576524843 }, 
					{ lat: -41.152794858446484, lng: 174.89551525759643 }, 
					{ lat: -41.14749524292971, lng: 174.8831556383354 }
				]
			},
			{ 
				name: "Tawa",
				id: 45,
				coords: [
					{ lat: -41.14948869485587, lng: 174.83204078911365 }, 
					{ lat: -41.1493594345193, lng: 174.83135414359916 }, 
					{ lat: -41.15465889935331, lng: 174.82980919119154 }, 
					{ lat: -41.154529649210225, lng: 174.82723427051212 }, 
					{ lat: -41.16409347146464, lng: 174.81178474643588 }, 
					{ lat: -41.1679702991377, lng: 174.81195640781448 }, 
					{ lat: -41.167711851095426, lng: 174.80697822783435 }, 
					{ lat: -41.17662771944539, lng: 174.80663490507712 }, 
					{ lat: -41.177919773607535, lng: 174.80886650299922 }, 
					{ lat: -41.18244176246527, lng: 174.80560493680537 }, 
					{ lat: -41.18618831671058, lng: 174.81178474643588 }, 
					{ lat: -41.18825528936588, lng: 174.81298637608626 }, 
					{ lat: -41.18980547603744, lng: 174.8310108208419 }, 
					{ lat: -41.18334612277059, lng: 174.8316974663564 }, 
					{ lat: -41.17947020495949, lng: 174.83753395322967 }, 
					{ lat: -41.17068394203348, lng: 174.84234047183116 }, 
					{ lat: -41.17003784677643, lng: 174.84749031318992 }, 
					{ lat: -41.16047489203006, lng: 174.8533268000632 }, 
					{ lat: -41.15440039881231, lng: 174.83976555115177 }, 
					{ lat: -41.1493594345193, lng: 174.84079551942352 }, 
					{ lat: -41.14948869485587, lng: 174.83204078911365 }
				]
			},
			{ 
				name: "Te Aro",
				id: 46,
				coords: [
					{ lat: -41.288899091775654, lng: 174.77059660451235 },
					{ lat: -41.29415501805514, lng: 174.76767836107572 },
					{ lat: -41.29534802210609, lng: 174.7711545039929 },
					{ lat: -41.296798943573435, lng: 174.77055368916768 },
					{ lat: -41.298411040684385, lng: 174.7754460384585 },
					{ lat: -41.297927415734854, lng: 174.7757893612158 },
					{ lat: -41.29947500295038, lng: 174.7807675411959 },
					{ lat: -41.29334892188511, lng: 174.78437243014702 },
					{ lat: -41.29167220994414, lng: 174.78660402806915 },
					{ lat: -41.29067261116783, lng: 174.78651819737985 },
					{ lat: -41.2883831498091, lng: 174.78600321324396 },
					{ lat: -41.2883831498091, lng: 174.78553114445276 },
					{ lat: -41.29031791114628, lng: 174.7855740597974 },
					{ lat: -41.29044689319542, lng: 174.78368578463255 },
					{ lat: -41.28938278366387, lng: 174.78299913911803 },
					{ lat: -41.289544013496396, lng: 174.78038130309398 },
					{ lat: -41.29109179961615, lng: 174.77982340361345 },
					{ lat: -41.28896358423461, lng: 174.77613268397303 },
					{ lat: -41.28864112130237, lng: 174.77497396966731 },
					{ lat: -41.288673367667315, lng: 174.770639519857 },
					{ lat: -41.288899091775654, lng: 174.77059660451235 }
				]
			},
			{ 
				name: "Thorndon",
				id: 47,
				coords: [
					{ lat: -41.26487219944977, lng: 174.77310440327577 },
					{ lat: -41.267710852100805, lng: 174.76928493760136 },
					{ lat: -41.2792254839429, lng: 174.7664954401987 },
					{ lat: -41.27877396801642, lng: 174.7654225565823 },
					{ lat: -41.279354487919605, lng: 174.76477882641248 },
					{ lat: -41.2806122633295, lng: 174.76683876295593 },
					{ lat: -41.27890297288558, lng: 174.76949951432465 },
					{ lat: -41.27919323290889, lng: 174.77134487414486 },
					{ lat: -41.27816119140574, lng: 174.7725035884506 },
					{ lat: -41.277000125208225, lng: 174.7744347789601 },
					{ lat: -41.27709688151354, lng: 174.77773926049866 },
					{ lat: -41.27777417163477, lng: 174.777825091188 },
					{ lat: -41.2777419198836, lng: 174.77988502773147 },
					{ lat: -41.275645521873706, lng: 174.78001377376546 },
					{ lat: -41.275581016251806, lng: 174.78130123410511 },
					{ lat: -41.274903703373234, lng: 174.78177330289634 },
					{ lat: -41.27461342427385, lng: 174.78327533995935 },
					{ lat: -41.27016231644155, lng: 174.78082916531395 },
					{ lat: -41.267388284150876, lng: 174.78091499600325 },
					{ lat: -41.2666463718184, lng: 174.7816016415177 },
					{ lat: -41.26632379860965, lng: 174.78100082669255 },
					{ lat: -41.2672592565247, lng: 174.77889797480435 },
					{ lat: -41.265356069421934, lng: 174.77576515464446 },
					{ lat: -41.26487219944977, lng: 174.77310440327577 }
				]
			},
			{ 
				name: "Vogeltown",
				id: 48,
				coords: [
					{ lat: -41.31027199942891, lng: 174.768738871145 },
					{ lat: -41.31056211990149, lng: 174.76867449812798 },
					{ lat: -41.31051376657904, lng: 174.76824534468142 },
					{ lat: -41.31159364890356, lng: 174.76796639494117 },
					{ lat: -41.312012702898485, lng: 174.76770890287324 },
					{ lat: -41.31196435065192, lng: 174.76687205365243 },
					{ lat: -41.31230281562468, lng: 174.76650727322283 },
					{ lat: -41.31317314605687, lng: 174.76680768063542 },
					{ lat: -41.31309256039389, lng: 174.76637852718886 },
					{ lat: -41.31488153866969, lng: 174.76618540813791 },
					{ lat: -41.31494600543879, lng: 174.7666789346015 },
					{ lat: -41.31530057152923, lng: 174.7665931039122 },
					{ lat: -41.31617086193793, lng: 174.7664858155505 },
					{ lat: -41.316364258228525, lng: 174.77170002992625 },
					{ lat: -41.31040094202055, lng: 174.7720218950112 },
					{ lat: -41.31027199942891, lng: 174.768738871145 }
				]
			},
			{ 
				name: "Wadestown",
				id: 49,
				coords: [
					{ lat: -41.25705021057865, lng: 174.77583765535505 },
					{ lat: -41.258340677089194, lng: 174.77515100984056 },
					{ lat: -41.257759970314595, lng: 174.77317690398633 },
					{ lat: -41.25969563948484, lng: 174.76914286158868 },
					{ lat: -41.262276442474, lng: 174.763478036094 },
					{ lat: -41.2651797239302, lng: 174.76433634298715 },
					{ lat: -41.26769579678155, lng: 174.7694861843459 },
					{ lat: -41.264857143475986, lng: 174.77309107329702 },
					{ lat: -41.26595391051855, lng: 174.77798342258785 },
					{ lat: -41.26730871496115, lng: 174.7796142056848 },
					{ lat: -41.260727972918886, lng: 174.78768229048018 },
					{ lat: -41.258018062851825, lng: 174.78278994118938 },
					{ lat: -41.25666306565371, lng: 174.7769534543161 },
					{ lat: -41.25705021057865, lng: 174.77583765535505 }
				]
			},
			{ 
				name: "Wellington Central",
				id: 50,
				coords: [
					{ lat: -41.27811704029901, lng: 174.77292224475866}, 
					{ lat: -41.282498846528576, lng: 174.773584837632}, 
					{ lat: -41.28870590174301, lng: 174.77066942898918}, 
					{ lat: -41.28907100425027, lng: 174.77468915908764}, 
					{ lat: -41.2910956265288, lng: 174.77968069206707}, 
					{ lat: -41.2899671562878, lng: 174.780254939224}, 
					{ lat: -41.2882412228698, lng: 174.78016659350754}, 
					{ lat: -41.28790930736274, lng: 174.77954817349237}, 
					{ lat: -41.28601735673048, lng: 174.77919479062658}, 
					{ lat: -41.28591777886178, lng: 174.77950400063418}, 
					{ lat: -41.28379341479487, lng: 174.77906227205187}, 
					{ lat: -41.282930372137514, lng: 174.77945982777595}, 
					{ lat: -41.28213370724609, lng: 174.77932730920125}, 
					{ lat: -41.27954447918217, lng: 174.77703032057357}, 
					{ lat: -41.278946950425414, lng: 174.77680945628245}, 	
					{ lat: -41.27811704029901, lng: 174.77292224475866}, 
				]
			},
			{ 
				name: "Wilton",
				id: 51,
				coords: [
					{ lat: -41.25712807563371, lng: 174.75119361962808 },
					{ lat: -41.264433270688286, lng: 174.740768825087 },
					{ lat: -41.2706088435765, lng: 174.7493383595826 },
					{ lat: -41.269944831507665, lng: 174.75260715109124 },
					{ lat: -41.27206964635565, lng: 174.75243045965834 },
					{ lat: -41.27771334997451, lng: 174.75278384252417 },
					{ lat: -41.279904303414966, lng: 174.75287218824062 },
					{ lat: -41.279970694795324, lng: 174.75384399112156 },
					{ lat: -41.27857646162485, lng: 174.75561090545054 },
					{ lat: -41.27585430149989, lng: 174.75631767118213 },
					{ lat: -41.27445998039639, lng: 174.7559642883163 },
					{ lat: -41.27432718635699, lng: 174.75675939976438 },
					{ lat: -41.27505755023055, lng: 174.75737781977952 },
					{ lat: -41.274991153852454, lng: 174.7591447341085 },
					{ lat: -41.27246804144063, lng: 174.75826127694404 },
					{ lat: -41.27187044790142, lng: 174.76259021705008 },
					{ lat: -41.27074164517985, lng: 174.7665657742903 },
					{ lat: -41.26728871569788, lng: 174.7693928372167 },
					{ lat: -41.26523015162425, lng: 174.76435713137906 },
					{ lat: -41.263038705650196, lng: 174.76453382281198 },
					{ lat: -41.261444880567694, lng: 174.7633853284981 },
					{ lat: -41.26197615991725, lng: 174.75790789407822 },
					{ lat: -41.25712807563371, lng: 174.75119361962808 }
				]
			},
			{ 
				name: "Woodridge",
				id: 52,
				coords: [
					{ lat: -41.20218935345921, lng: 174.83395519304838 }, 
					{ lat: -41.20228622057957, lng: 174.83378353167285 }, 
					{ lat: -41.20903427702406, lng: 174.82790412956138 }, 
					{ lat: -41.21897751281106, lng: 174.8252862935847 }, 
					{ lat: -41.21959084474645, lng: 174.83103694966454 }, 
					{ lat: -41.22091433090494, lng: 174.8309511189768 }, 
					{ lat: -41.22130168764091, lng: 174.83447017717492 }, 
					{ lat: -41.214458047540475, lng: 174.84682979621223 }, 
					{ lat: -41.21342498306214, lng: 174.83889045759452 }, 
					{ lat: -41.207290826720765, lng: 174.84236660044877 }, 
					{ lat: -41.202576821080385, lng: 174.83756008193427 }, 
					{ lat: -41.20218935345921, lng: 174.83395519304838 }
				]
			},
		]
	},
	{
		id: 2,
		name: "Auckland - coming soon",
		location: { lat: -36.85, lng: 174.77 },
		zoomLevel: 11,
		suburbs: [
			{
				name: "Kingsland",
				id: 5,
			}
		]
	},
	{
		id: 3,
		name: "Christchurch - coming soon",
		location: { lat: -43.53, lng: 172.63 },
		zoomLevel: 12
	},
];

// add function to each suburb to calculate the centroid of the polygon
// so it can be called with (e.g.) randomSuburb[currentQuestion].centre 
// returns a lat/lng object which can be passed as props to SimpleMap

CityDataJSON[0].suburbs.map(function(s) {s.centre = {
	lng: (Math.min(...s.coords.map(o => o.lng)) + Math.max(...s.coords.map(o => o.lng))) / 2, 
	lat: (Math.min(...s.coords.map(o => o.lat)) + Math.max(...s.coords.map(o => o.lat))) / 2
}});

CityDataJSON[0].suburbs.map(function(s) {
	s.distanceFrom = function(x1, y1) {
		const x2 = s.centre.lng;
		const y2 = s.centre.lat;
		return Math.hypot(x2-x1, y2-y1);
		// console.log(Math.hypot(x2-x1, y2-y1));
	}
});

export default CityDataJSON;