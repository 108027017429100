import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Card, } from 'react-bootstrap';
import SimpleMap from './components/SimpleMap.js';
import CitySelectForm from './components/CitySelectForm.jsx';
import CityDataJSON from './CityData.js';
import Game from './components/Game.jsx';

require("./styles.css");

export default function App() {
  const [city, setCity] = useState('Wellington');
  const [cityData, setCityData] = useState(CityDataJSON[0]);
  const [mapCentre, setMapCentre] = useState({ location: { lat: -41.297, lng: 174.77 }, zoomLevel: 12})
  const [gameActive, setGameActive] = useState(false);
  const [difficulty, setDifficulty] = useState(1);
  const [randomSuburbs, setRandomSuburbs] = useState([{}]);
  const [suburbCoords, setSuburbCoords] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(-1);
  const [scoreHistory, setScoreHistory] = useState([]);
  // const [showScore, setShowScore] = useState(false);
  const [showAnswerBox, setShowAnswerBox] = useState(false);
  const [answerBoxContent, setAnswerBoxContent] = useState('');
  const [width, setWidth] = useState(window.innerWidth);

  const updateDimensions = () => {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function Header(props) {
    if (gameActive) {
      return <h2 className="my-2 display-4 text-center"><a href="/" className="link-dark">Playing in <em>{props.city}</em></a></h2>
    } else {
      return <h2 className="my-2 display-4 text-center">suburble</h2> 
    }
  }

  return (
    <>
      <Header city={city}/>
      <Container>
        <Card className="p-2">
          <Row>
            <Col md="9" className="my-2" >
              <SimpleMap 
                location={mapCentre.location} 
                zoomLevel={mapCentre.zoomLevel} 
                suburbCoords={suburbCoords} // for polygon
                randomSuburbs={randomSuburbs}
                currentQuestion={currentQuestion}
                width={width} 
                showAnswerBox={showAnswerBox}
                answerBoxContent={answerBoxContent}
              />    
            </Col>
            <Col md="3">         
              {gameActive 
                ? 
                  <>
                    <Game cityData={cityData} 
                      suburbCoords={suburbCoords}
                      setSuburbCoords={setSuburbCoords} 
                      mapCentre={mapCentre}
                      setMapCentre={setMapCentre} 
                      currentQuestion={currentQuestion}
                      setCurrentQuestion={setCurrentQuestion}
                      scoreHistory={scoreHistory}
                      setScoreHistory={setScoreHistory} 
                      randomSuburbs={randomSuburbs}
                      setRandomSuburbs={setRandomSuburbs}
                      showAnswerBox={showAnswerBox}
                      setShowAnswerBox={setShowAnswerBox}
                      setAnswerBoxContent={setAnswerBoxContent}
                      difficulty={difficulty}
                      width={width} 
                    />                
                  </>
                :
                  <CitySelectForm 
                    setGameActive={setGameActive} 
                    city={city} 
                    setCity={setCity} 
                    cityData={cityData}
                    setCityData={setCityData} 
                    CityDataJSON={CityDataJSON}  
                    setSuburbCoords={setSuburbCoords} 
                    setMapCentre={setMapCentre} 
                    setCurrentQuestion={setCurrentQuestion}
                    difficulty={difficulty}
                    setDifficulty={setDifficulty}
                    /
                  >
              }
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  )
}