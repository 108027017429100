import React, { useState } from 'react';
import { Row, Col, Button, Form, Card, CardGroup, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionSquareFill } from 'react-bootstrap-icons';

export default function CitySelectForm(props) {
  
  function handleChange(event) {
    props.setCity(event.target.value);
    for (var i=0; i<props.CityDataJSON.length; i++) {
      if (event.target.value == props.CityDataJSON[i].name) {
        props.setCityData(props.CityDataJSON[i]);
        props.setMapCentre({location: props.CityDataJSON[i].location, zoomLevel: props.CityDataJSON[i].zoomLevel});
      }
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    props.setGameActive(true);
    props.setCurrentQuestion(0);
  }

  function CityList() {
    const cities = props.CityDataJSON.map((city) => <option key={city.id} id={city.id}>{city.name}</option>);
    return (
      <>
        {cities}
      </>
    );
  }

  function handleLevelChange(event) {
    // event.preventDefault();
    props.setDifficulty(event.target.id);
  }

  function DifficultyButtons() {
    const difficulties = [
      {level: 1, name: 'Easy', description: 'Choose from the correct answer and three random suburbs'},
      {level: 2, name: 'Medium', description: 'Choose from the correct answer and three nearby suburbs'},
      {level: 3, name: 'Hard', description: 'No multiple choice options'}
    ]

    var buttons = difficulties.map((diff) => (     
        <Col xs={4} className="px-0 text-center">
          <h6 className="text-muted fw-light">{diff.name}</h6>
            <OverlayTrigger
              key={diff.level}
              placement={"top"}
              overlay={
                <Tooltip>
                  {diff.description}
                </Tooltip>
              }
            >
              <QuestionSquareFill color="orange" size={"1.5rem"}/>
            </OverlayTrigger>
          <Form.Group>
            <Form.Check checked={props.difficulty == diff.level} type="radio" name="level" key={diff.level} id={diff.level} onChange={handleLevelChange} />
          </Form.Group>
        </Col>
      
    ))  

    return buttons;
  }

  return (
    <div>
      <Form onSubmit={handleSubmit} controlId="formSelect" className="mt-1">
        <Form.Select value={props.city} custom onChange={handleChange} >
          <CityList />        
        </Form.Select>
        <Row className="my-3">
          <DifficultyButtons />
        </Row>
        <div className="d-grid gap-2">
          <Button type="submit" >Play</Button>
        </div>
      </Form>
    </div>
  );
}