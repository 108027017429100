import React from 'react';
import { GoogleMap, useJsApiLoader, Polygon, OverlayView, } from '@react-google-maps/api';
import { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

  const NEW_ZEALAND_BOUNDS = {
    north: -34.36,
    south: -47.35,
    west: 166.28,
    east: -175.81,
  };

const mapStyles = [
  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
]

export default function SimpleMap(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDnMEOlnnEDXJjAKUqfvT8fjoUFc3vAV6A"
  })  
  
  if ( props.width <= 768 ) {
  var containerStyle = {
    width: '100%',
    height: '55vh'
  };
 } else {
  var containerStyle = {
    width: '100%',
    height: '75vh'
  };
};

  const paths = props.suburbCoords;

  const polygonoptions = {
    fillColor: "lightblue",
    fillOpacity: 0.5,
    strokeColor: "black",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }

  return isLoaded ? (
    
    <GoogleMap
      id="MainMap"
      mapContainerStyle={containerStyle}
      center={props.location}
      // restriction={
      //   {"latLngBounds": NEW_ZEALAND_BOUNDS,
      //   "strictBounds": false,}
      // }
      zoom={props.zoomLevel}
      // options={{ mapId: 'f196fafdeb751614' }} // costs $$ to use
      options={{ styles: mapStyles, disableDefaultUI: true }} // this is a better way to do it
    >
      <Polygon
        paths={paths}
        options={polygonoptions}
      />
      {props.showAnswerBox ?
        <OverlayView position={props.location} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
          <div className="popupcard text-center position-absolute top-50 start-50 translate-middle rounded-3" style={{ "border": "none"}}>
            {props.answerBoxContent}      
          </div>
        
        </OverlayView>
        
      :
        <></>
      }
    </GoogleMap>
    
  ) : <></>
}