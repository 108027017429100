import React, { useState, useEffect, useRef } from 'react';
import { Badge, Button, ButtonGroup, Container, Form, InputGroup, Row, Col, Modal, ModalHeader, ModalBody } from 'react-bootstrap';
import { Check, X, Dot } from 'react-bootstrap-icons';
import { CSSTransition } from 'react-transition-group';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

function shuffleFisherYates(array) {
	let i = array.length;
	while (i--) {
		const ri = Math.floor(Math.random() * i);
		[array[i], array[ri]] = [array[ri], array[i]];
	}
	return array;
}

export default function Game(props) {
	const [scoreModal, setScoreModal] = useState(false);
	const [possibleAnswers, setPossibleAnswers] = useState([]);
	const [userInput, setUserInput] = useState();
	const {currentQuestion, randomSuburbs} = props;
	const typeaheadRef = useRef(null);
	var shuffledList = [];

	function suburbList() {
    var sublist = [];
    for (var i=0; i<randomSuburbs.length; i++) {
      sublist.push(randomSuburbs[i].name);
    }
    return sublist.sort(); //A-Z order for the autocomplete
  }

	useEffect(() => {
		props.setRandomSuburbs(shuffleFisherYates(props.cityData.suburbs));	
	}, []) // fire once only

	useEffect(() => {
		if (randomSuburbs.length > 2) {
			// set coords for drawing polygon on map
			props.setSuburbCoords(randomSuburbs[currentQuestion].coords);

			// set the new map centre			// if it's a large suburb, zoom out a bit
			// TODO: make getArea a method on the suburb object
			if (getArea(randomSuburbs[currentQuestion].coords) > 4) {
				props.setMapCentre({ location: randomSuburbs[currentQuestion].centre, zoomLevel: 12});
			} else {
				props.setMapCentre({ location: randomSuburbs[currentQuestion].centre, zoomLevel: 13});
			}

			// generate answers for buttons and store in state
			if (props.difficulty == 1) {
				// multiple choice: correct answer + 3 wrong random suburbs
				var shuffledList = shuffleFisherYates([...randomSuburbs]).slice(0,4); // grab 4 random suburbs
				if (shuffledList.indexOf(randomSuburbs[currentQuestion]) == -1) { 		// check to make sure the correct answer is in there
					shuffledList.shift();																								// if it isn't, remove the first (wrong) answer
					shuffledList.push(randomSuburbs[currentQuestion]); 									// and add the correct answer.
				}																			// this is a silly way to do it. TODO: add the correct answer FIRST; it gets shuffled anyway
				setPossibleAnswers(shuffleFisherYates([...shuffledList]));
			} else if (props.difficulty == 2) {
				// multiple choice: correct answer + 3 wrong NEARBY suburbs
				const sortedlist = [...randomSuburbs].sort((a, b) => a.distanceFrom(randomSuburbs[currentQuestion].centre.lng, randomSuburbs[currentQuestion].centre.lat) - b.distanceFrom(randomSuburbs[currentQuestion].centre.lng, randomSuburbs[currentQuestion].centre.lat));
				let possAnswers = []
				possAnswers[0] = randomSuburbs[currentQuestion];													// add correct answer to array
				const shuffledclosestnine = shuffleFisherYates(sortedlist.slice(1, 9));		// then take the 2nd-9th closest suburbs
				possAnswers.splice(1, 0, ...shuffledclosestnine);													// and put three of them into the array of possible answers
				setPossibleAnswers(shuffleFisherYates(possAnswers.slice(0, 4)));					
			}
		}
	}, [randomSuburbs, currentQuestion]) // fires when randomSuburbs is set (start of game) and every time currentQuestion changes

	function AnswerButtons() {
		if (randomSuburbs.length < 2 || !possibleAnswers) {return null;}
		const shuffledList = possibleAnswers.map((sub) => (
      	<Button size="lg" className="btn-flat d-grid my-2" onClick={(e) => handleAnswerClick(e)} name={sub.name} key={sub.id}>
      		{sub.name} 
      		{/*{sub.distanceFrom(randomSuburbs[currentQuestion].centre.lng,randomSuburbs[currentQuestion].centre.lat)}*/}
      	</Button>
	    )
	  )	
		return (
			<>	
				<Row className="mt-1">
					<Col md={12} xs={6}>	
						<div className="d-grid gap-1">
							{shuffledList[0]}
						</div>	
					</Col>
					<Col md={12} xs={6}>	
						<div className="d-grid gap-1">
							{shuffledList[1]}
						</div>	
					</Col>
				
					<Col md={12} xs={6}>	
						<div className="d-grid gap-1">
							{shuffledList[2]}
						</div>	
					</Col>
					<Col md={12} xs={6}>	
						<div className="d-grid gap-1">
							{shuffledList[3]}
						</div>	
					</Col>
				</Row>
			</>
		)
	}

	function handleAnswerClick(e) {
		// disable buttons while answerBox is shown
		if (props.showAnswerBox) {return null;} 		

		// flick view back to suburb centre if necessary
		props.setMapCentre({ location: randomSuburbs[currentQuestion].centre, zoomLevel: 13}); // isn't working?!

		// add 'true' or 'false' to the scoreHistory state
		const isCorrect = (e.target.name == randomSuburbs[currentQuestion].name); 
		props.setScoreHistory([...props.scoreHistory, isCorrect]) 

		// build the answer box content which gets sent as props to SimpleMap
		if (isCorrect) {
			let content = (<div >
											<Check color="green" className="outlined" size="5rem" />
											<h3><Badge bg="success">{props.randomSuburbs[props.currentQuestion].name}</Badge></h3>
									</div>);
			props.setAnswerBoxContent(content)
		} else {
			let content = (<>
										<X color="red" className="outlined" size="5rem" />
										<h3><Badge bg="secondary">{props.randomSuburbs[props.currentQuestion].name}</Badge></h3>
									</>);
			props.setAnswerBoxContent(content)
		}
		props.setShowAnswerBox(true);
		setTimeout(() => props.setShowAnswerBox(false), 1200);
		if (currentQuestion+1 < 10) {
			setTimeout(() => props.setCurrentQuestion(currentQuestion + 1), 1000);
		} else {
			setTimeout(() => setScoreModal(true), 1200);
		}
	}

	function ResultsGraph() {
		let graph = []
			for (let i=0; i<10; i++) {
				if (props.scoreHistory[i] === true) {
					graph.push(<Col className="p-0" xs={1}><Check color="green" size="1.5rem" /></Col>)
				} else if (props.scoreHistory[i] === false) {
					graph.push(<Col className="p-0" xs={1}><X color="red" size="1.5rem" /></Col>)
				} else {
					graph.push(<Col className="p-0" xs={1} ><Dot color="grey" size="1.5rem" /></Col>)
				}
			}
		return graph;
	}
		
	function getScore() {
		return props.scoreHistory.filter(function(x){return x==true}).length
	}

	function getArea(suburbCoords) { 										// this should have been a method on the suburb object
		if (suburbCoords == undefined) {return null;}
    var total = 0;

    for (var i = 0, l = suburbCoords.length; i < l; i++) {
      var addX = suburbCoords[i].lat;
      var addY = suburbCoords[i == suburbCoords.length - 1 ? 0 : i + 1].lng;
      var subX = suburbCoords[i == suburbCoords.length - 1 ? 0 : i + 1].lat;
      var subY = suburbCoords[i].lng;

      total += (addX * addY * 0.5);
      total -= (subX * subY * 0.5);
    }

    return Math.abs(total)*10000;
	}

	function ClosestSuburbs() {
		// Find and return the ten closest suburbs
		const sortedlist = [...randomSuburbs].sort((a, b) => a.distanceFrom(randomSuburbs[currentQuestion].centre.lng, randomSuburbs[currentQuestion].centre.lat) - b.distanceFrom(randomSuburbs[currentQuestion].centre.lng, randomSuburbs[currentQuestion].centre.lat));
		const listitems = sortedlist.map((sub) => (
      	<li>{sub.name}</li>
	    ))
		return listitems;
	}

	function handleAnswerSubmit(event) {
		event.preventDefault();
    // disable buttons while answerBox is shown
		if (props.showAnswerBox) {return null;} 		

		// flick view back to suburb centre if necessary
		props.setMapCentre({ location: randomSuburbs[currentQuestion].centre, zoomLevel: 13}); // isn't working?!

		// add 'true' or 'false' to the scoreHistory state
		const isCorrect = (userInput == randomSuburbs[currentQuestion].name); 
		props.setScoreHistory([...props.scoreHistory, isCorrect]) 

		// build the answer box content which gets sent as props to SimpleMap
		if (isCorrect) {
			let content = (<div >
											<Check color="green" className="my-2" size="5rem" />
											<h3><Badge bg="success">{props.randomSuburbs[props.currentQuestion].name}</Badge></h3>
									</div>);
			props.setAnswerBoxContent(content)
		} else {
			let content = (<>
										<X color="red" className="outlined my-2" size="5rem" />
										<h3><Badge bg="secondary">{props.randomSuburbs[props.currentQuestion].name}</Badge></h3>
									</>);
			props.setAnswerBoxContent(content)
		}
		props.setShowAnswerBox(true);
		setTimeout(() => props.setShowAnswerBox(false), 1500);
		typeaheadRef.current.clear();

		if (currentQuestion+1 < 10) {
			setTimeout(() => props.setCurrentQuestion(currentQuestion + 1), 1500);
	
		} else {
			setTimeout(() => setScoreModal(true), 1500);
		}
	}
  
	function InputBox() {
		return null;
	}

	function isNarrow() {
		if (props.width < 768) {
			return 'dropup';
		} else {
			return null;
		}
	}

	return (
		<>
			{ parseInt(props.difficulty) < 3 ?
				<AnswerButtons />
			:
				<>
				<Col className="my-2 pe-2">
					<Form onSubmit={handleAnswerSubmit}> 
							{ props.width < 768 ? 
								<Typeahead 
									clearButton
									autoFocus
									dropup
									size="lg"
									id="suburbinput"
									minLength={3}
									placeholder="Where is this?"
									onChange={(selected) => {
									  setUserInput(selected);
									}}
									options={suburbList()}
									ref={typeaheadRef}
								/>
							:
								<Typeahead 
									clearButton
									autoFocus
									size="lg"
									id="suburbinput"
									minLength={3}
									placeholder="Where is this?"
									onChange={(selected) => {
									  setUserInput(selected);
									}}
									options={suburbList()}
									ref={typeaheadRef}
								/>
							}
							<div className="d-grid">
						<Button type="submit" size="lg" className="btn-flat my-4" id="submitanswer" >
						  Go
					  </Button>
					</div>
					</Form>
					
				</Col>
			</>
			}

			<Row className="my-2 justify-content-center">
				<ResultsGraph score={props.scoreHistory}/>
			</Row>
			
			<Modal show={scoreModal}>
        <Modal.Body>
        	<Row className="my-2 justify-content-center">
           	<ResultsGraph score={props.scoreHistory}/>
          </Row>
            <h1 className="text-center">{getScore()} out of {props.scoreHistory.length}</h1>
            <a href="https://www.suburble.nz" className="btn btn-info" type="Button">Play again</a>
        </Modal.Body>
      </Modal>
		</>
	);
}

